<template>
  <div class="view-container">
    <div class="view">
      <div class="view-body">
        <el-form>
          <div class="container-fluid p-0">
            <div class="row">
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.client_name')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="client_name"
                      v-model="form.client_name"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.nip')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="nip"
                      v-model="form.nip"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.street')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="street"
                      v-model="form.street"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.post_code')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="post_code"
                      v-model="form.post_code"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.city')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="city"
                      v-model="form.city"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.bank')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="Bank"
                      v-model="form.Bank"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 col-md-6">
                <el-form-item :label="$t('system.BankN')">
                  <el-input
                      @keyup.ctrl.enter.native="onSave"
                      name="BankN"
                      v-model="form.BankN"
                  >
                  </el-input>
                </el-form-item>
              </div>
              <div class="col-12 d-flex justify-content-end">
                <el-button
                    :loading="loading"
                    @click.prevent.stop="onSave"
                    type="primary"
                >
                  {{ $t('system.save') }}
                </el-button>
              </div>
            </div>
          </div>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters} from 'vuex';
import axios from 'axios';
import Global from '../../helpers/global';
import notification from '../../notification/notify';

export default {
  name: 'PersonalData',
  data() {
    return {
      form: {},
      initialize: 'system_settings',
      method: 'put',
      store: 'system_settings',
      backend_errors: [],
      loading: false,
      countries: [],
    };
  },
  beforeMount() {
    this.fetchData();
  },
  computed: {
    ...mapGetters([
      'uConfig',
    ]),
  },
  methods: {
    fetchData() {
      var vm = this;
      axios.get(`${this.initialize}/form`)
          .then(function (response) {
            vm.form = response.data.form;
            vm.form.country_id = parseInt(vm.form.country_id);
            vm.countries = response.data.countries;
          })
          .catch(function (error) {
            notification.notify(
                vm.$t('notify.error'),
                error.response.data.error,
                'error');
          });
    },
    onSave() {
      var vm = this;
      vm.loading = true;
      let form = this.form;
      axios[this.method](this.store, form)
          .then(function (response) {
            vm.loading = false;
            if (response.data.saved) {
              vm.$store.dispatch('getSystemSetting').then(() => {
                notification.notify(
                    vm.$t('notify.success'),
                    response.data.message,
                    'success');
              });
            } else {
              notification.notify(
                  vm.$t('notify.error'),
                  response.data.message,
                  'error');
            }
          })
          .catch(function (error) {
            vm.loading = false;
            let dataErrors = error.response.data.errors;
            let errors = Global.getErrors(vm, dataErrors, 1);

            vm.$store.commit('SET_STICKY_ARRAY', {
              array: errors,
            });
            vm.backend_errors = dataErrors;
          });
    },
  },
};
</script>

<style scoped>

</style>
